import { useResolvedPath } from "react-router-dom";
import StyledDivContainer from "./styledComponents/StyledDivContainer";
import BlogNavButton from "./BlogNavButton";
import { getBlogType } from "../utils/urlUtils";
import styled from "styled-components";

const StyledErrorMessage = styled.div`
  margin: 50px 0;
`;
const ErrorPage = () => {
  const pathname = useResolvedPath().pathname;
  const blogType = getBlogType({ pathname });
  return (
    <StyledDivContainer>
      <StyledErrorMessage>
        The page you're looking for does not exist. I'll keep working to create
        more content!
      </StyledErrorMessage>
      <BlogNavButton buttonText="Back to Gallery" to={`/${blogType}`} />
    </StyledDivContainer>
  );
};

export default ErrorPage;
