import { useContext, useEffect } from "react";
import { STANDARD_PAGE } from "../constants/pageTypes";
import { BlogContext } from "../BlogContext";

const useTrack = ({ path, contentType = STANDARD_PAGE }) => {
  const { isTrackingLoaded } = useContext(BlogContext);
  useEffect(() => {
    if (isTrackingLoaded) {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(["setPath", path]);
      _hsq.push(["setContentType", contentType]);
      _hsq.push(["trackPageView"]);
    }
  }, [path, contentType, isTrackingLoaded]);
};

export default useTrack;
