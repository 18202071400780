import styled from "styled-components";
import React, { useEffect, useCallback, useState } from "react";
import { SCREEN_WIDTHS } from "../constants/screenSizes";
import MainMenu from "./MainMenu";

const MIN_DIST_FROM_TOP = 200;

const FixedMenu = styled.div`
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
  display: flex;
  overflow: hidden;
  position: fixed;
  height: 50px;
  top: ${({ top }) => top}px;
  transition: top 0.5s ease;
  width: 100vw;
  z-index: 2;

  @media screen and (min-width: ${SCREEN_WIDTHS.mobile}) {
    flex-wrap: nowrap;
    height: 75px;
  }
`;

const TopBar = () => {
  const [shouldShowStickyMenu, setShouldShowStickyMenu] = useState(false);
  const [scrollPos, setScrollPos] = useState(window.scrollY);

  const handleCheckPlacement = useCallback(() => {
    const newScrollPos = window.scrollY;
    const isScrollingUp = scrollPos > newScrollPos;

    const hasEnoughRoomToShowMenu = newScrollPos > MIN_DIST_FROM_TOP;

    if (isScrollingUp && hasEnoughRoomToShowMenu) {
      if (!shouldShowStickyMenu) {
        setShouldShowStickyMenu(true);
      }
      return setScrollPos(newScrollPos);
    }

    setScrollPos(newScrollPos);
    return setShouldShowStickyMenu(false);
  }, [shouldShowStickyMenu, scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleCheckPlacement);

    return function cleanup() {
      window.removeEventListener("scroll", handleCheckPlacement);
    };
  }, [handleCheckPlacement]);

  return (
    <React.Fragment>
      <MainMenu />
      <FixedMenu top={shouldShowStickyMenu ? 0 : -120}>
        <MainMenu />
      </FixedMenu>
    </React.Fragment>
  );
};

export default TopBar;
