import styled from "styled-components";
import BlogNavButton from "./BlogNavButton";
import { useResolvedPath } from "react-router-dom";
import { getBlogType } from "../utils/urlUtils";

const StyledOuterButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const StyledInnerContainer = styled.div`
  align-items: center;
  margin: 50px 0;
  display: flex;
  gap: 15px;
`;

const PreviousNextBackButtons = ({ nextBlogDate, prevBlogDate }) => {
  const pathname = useResolvedPath().pathname;
  const blogType = getBlogType({ pathname });
  const backToGalleryButtonText =
    blogType === "handmades" ? "Back to Handmades" : "Back to Jude Blog";

  const isMostRecentBlog = !nextBlogDate;
  const isOldestBlog = !prevBlogDate;

  if (isMostRecentBlog) {
    return (
      <StyledOuterButtonContainer>
        <i>You're all caught up!</i>
        <StyledInnerContainer>
          <BlogNavButton buttonText="Previous" blogDate={prevBlogDate} />
          <BlogNavButton
            buttonText={backToGalleryButtonText}
            to={`/${blogType}`}
          />
        </StyledInnerContainer>
      </StyledOuterButtonContainer>
    );
  }

  if (isOldestBlog) {
    return (
      <StyledOuterButtonContainer>
        <i>This is just the beginning!</i>
        <StyledInnerContainer>
          <BlogNavButton
            buttonText={backToGalleryButtonText}
            to={`/${blogType}`}
          />
          <BlogNavButton buttonText="Next" blogDate={nextBlogDate} />
        </StyledInnerContainer>
      </StyledOuterButtonContainer>
    );
  }

  return (
    <StyledOuterButtonContainer>
      <StyledInnerContainer>
        <BlogNavButton buttonText="Previous" blogDate={prevBlogDate} />
        <BlogNavButton buttonText="Next" blogDate={nextBlogDate} />
      </StyledInnerContainer>
      <BlogNavButton buttonText={backToGalleryButtonText} to={`/${blogType}`} />
    </StyledOuterButtonContainer>
  );
};

export default PreviousNextBackButtons;
