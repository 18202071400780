import styled from "styled-components";
import jessie from "../images/landing.jpg";
import useLazyImg from "../utils/useLazyImg";
import { StyledLazyImage } from "./StyledLazyImage";
import { getRandomColor } from "../utils/colorUtils";
import { STANDARD_PAGE } from "../constants/pageTypes";
import useTrack from "../utils/useTrack";
import { ABOUT } from "../constants/routes";
import ContactLinks from "./ContactLinks";
import React from "react";

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 45px 0 150px 0;
`;

const StyledParagraph = styled.p`
  margin-bottom: 45px;
  margin-top: 45px;
  max-width: 700px;
`;

const StyledAboutImage = styled(StyledLazyImage)`
  flex-grow: 1;
`;

const About = () => {
  const lazyImgSrc = useLazyImg(jessie);

  useTrack({ path: `/${ABOUT}`, contentType: STANDARD_PAGE });

  return (
    <StyledDiv>
      <StyledAboutImage
        alt="jessie-landing"
        loadingColor={getRandomColor()}
        $minHeight="350px"
        $minWidth="350px"
        url={lazyImgSrc}
      />
      <StyledParagraph>
        My name is Jessie Samuelson, and this is my personal website. I am many
        things. I'm a daughter, a wife, a sister, a mother. I'm an artist, an
        engineer, a writer. Professionally, I work as a software engineer and
        people manager. In my personal life, I'm an avid knitter and sewist. I
        am a mother to two beautiful children, the younger of whom was born with
        several congenital abnormalities and ultimately died after 109 days of
        fighting and making the world and us undeniably better. This is my
        story.
      </StyledParagraph>
      <div>
        <ContactLinks />
      </div>
    </StyledDiv>
  );
};

export default About;
