"use es6";
import React from "react";
import { Link, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import { BLACK } from "../constants/colors";
import { isSubscribe } from "../utils/urlUtils";
import { SUBSCRIBE } from "../constants/routes";

const StyledFooter = styled.div`
  align-items: center;
  border-top: 1px solid #c4c9d0;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: center;
  margin: 15px;
  min-height: 200px;
  padding: 21px;
`;

const StyledSubscribe = styled.div`
  flex-basis: 50%;
  margin-bottom: 100px;
  margin-top: 50px;
`;

const StyledSubscribeButton = styled(Link)`
  border: 2px solid ${BLACK};
  border-radius: 0;
  color: ${BLACK} !important;
  margin-left: 12px;
  padding: 12px;
  text-decoration: none;
  text-underline: none;
`;

const Footer = () => {
  const thisYear = new Date().getFullYear();
  const { pathname } = useResolvedPath();
  const isSubscribeRoute = isSubscribe(pathname);

  if (isSubscribeRoute) {
    // don't show 'Subscribe' link
    return (
      <StyledFooter flexDirection="row">
        <span>{"© " + thisYear + " Jessie Samuelson"}</span>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter flexDirection="column">
      <StyledSubscribe>
        Follow along for updates
        <StyledSubscribeButton to={`/${SUBSCRIBE}`}>
          Subscribe
        </StyledSubscribeButton>
      </StyledSubscribe>
      <span>{"© " + thisYear + " Jessie Samuelson"}</span>
    </StyledFooter>
  );
};

export default Footer;
