import "./App.scss";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Script from "react-load-script";
import About from "./components/About";
import BlogIndividual from "./components/BlogIndividual";
import blogs from "./constants/blog.json";
import ScrollToTop from "./components/ScrollToTop";
import styled from "styled-components";
import { SCREEN_WIDTHS } from "./constants/screenSizes";
import TopBar from "./components/TopBar";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import BlogGallery from "./components/BlogGallery";
import Subscribe from "./components/Subscribe";
import { useState } from "react";
import { ABOUT, SUBSCRIBE, UNSUBSCRIBE } from "./constants/routes";
import { HANDMADES, JUDE } from "./constants/blogTypes";
import { BlogContext } from "./BlogContext";
import Unsubscribe from "./components/Unsubscribe";

const StyledDiv = styled.div`
  min-height: 90vh;
  padding: 0 60px 30px 60px;
  font-family: Open sans, sans-serif;

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    padding: 0 27px 15px 27px;
  }
`;

function App() {
  const mostRecentBlogDate = blogs[blogs.length - 1].date;
  const mostRecentBlogRoute = "/blog/" + mostRecentBlogDate;

  const [isTrackingLoaded, setIsTrackingLoaded] = useState(false);

  return (
    <BrowserRouter>
      <Script
        url="https://js.hs-scripts.com/5651882.js"
        onError={() => {}}
        onLoad={() => setIsTrackingLoaded(true)}
      />
      <ScrollToTop />
      <TopBar />
      <StyledDiv>
        <Menu />
        <BlogContext.Provider value={{ isTrackingLoaded }}>
          <Routes>
            <Route path="/" element={<Navigate to={mostRecentBlogRoute} />} />
            <Route path={`/${ABOUT}`} element={<About />} />
            <Route path={`/${SUBSCRIBE}`} element={<Subscribe />} />
            <Route path={`/${UNSUBSCRIBE}`} element={<Unsubscribe />} />
            <Route path={`/${JUDE}`} element={<BlogGallery />} />
            <Route path={`/${JUDE}/:date`} element={<BlogIndividual />} />
            <Route path={`/${HANDMADES}`} element={<BlogGallery />} />
            <Route path={`/${HANDMADES}/:date`} element={<BlogIndividual />} />
          </Routes>
        </BlogContext.Provider>
      </StyledDiv>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
