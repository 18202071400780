import styled from "styled-components";

const StyledTitle = styled.h1`
  font-family: Montserrat;
  font-size: 26px;
  margin: 21px 0 10px 0;
  display: flex;
  justify-content: center;
`;

export default StyledTitle;
