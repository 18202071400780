import styled from "styled-components";
import { Link } from "react-router-dom";
import { BLACK } from "../constants/colors";
import { SCREEN_WIDTHS } from "../constants/screenSizes";
import { SUBSCRIBE } from "../constants/routes";

// TODO: hamburger menu for mobile widths
const StyledSocialLinks = styled.div`
  display: flex;
  margin-right: 24px;
  gap: 30px;

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    font-size: 12px;
    gap: 12px;

    .email {
      display: none;
    }
  }
`;

const StyledSocialLink = styled.div`
  color: ${BLACK};
  cursor: pointer;
  text-transform: uppercase;

  a {
    color: ${BLACK};
    text-decoration: none;
  }
`;

const SocialLinks = () => {
  return (
    <StyledSocialLinks>
      <StyledSocialLink>
        <a
          href="https://www.instagram.com/jessie_by_hand/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
      </StyledSocialLink>
      <StyledSocialLink className="email">
        <a
          href="mailto:jessie.samuelson@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          Email
        </a>
      </StyledSocialLink>
      <StyledSocialLink>
        <Link to={`/${SUBSCRIBE}`}>Subscribe</Link>
      </StyledSocialLink>
    </StyledSocialLinks>
  );
};

export default SocialLinks;
