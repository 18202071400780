import BlogContent from "./BlogContent";
import StyledTitle from "./styledComponents/StyledTitle";
import PreviousNextBackButtons from "./PreviousNextBackButtons";
// import PostedDate from "./PostedDate";
import StyledDivContainer from "./styledComponents/StyledDivContainer";
import StyledContent from "./styledComponents/StyledContent";
import ErrorPage from "./ErrorPage";

const BlogEntry = ({
  entry,
  nextBlogDate,
  prevBlogDate,
  showButtons = true,
}) => {
  if (!entry) {
    return <ErrorPage />;
  }
  return (
    <StyledDivContainer>
      <StyledTitle>{entry.title}</StyledTitle>
      {/* <PostedDate date={entry.date} /> */}
      <StyledContent>
        {entry.content.map((item, index) => (
          <BlogContent item={item} key={index} blogTitle={entry.title} />
        ))}
      </StyledContent>
      {showButtons && (
        <PreviousNextBackButtons
          nextBlogDate={nextBlogDate}
          prevBlogDate={prevBlogDate}
        />
      )}
    </StyledDivContainer>
  );
};

export default BlogEntry;
