import styled from "styled-components";

const BlogVideoModal = ({ src }) => {
  const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
  `;

  return (
    <StyledDiv>
      <video controls width={360}>
        <source src={require(`../videos/${src}`)} />
      </video>
    </StyledDiv>
  );
};

export default BlogVideoModal;
