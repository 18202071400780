import styled from "styled-components";

export const StyledLazyImage = styled.div`
  background-color: ${({ loadingColor }) => loadingColor};
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  min-height: ${({ $minHeight }) => $minHeight};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  opacity: ${({ url }) => (url ? "1" : "0.25")};
  transition: opacity 0.25s ease-in-out;
`;

// // Create an Input component that'll render an <input> tag with some styles
// const Input = styled.input<{ $inputColor?: string; }>`
//   padding: 0.5em;
//   margin: 0.5em;
//   color: ${props => props.$inputColor || "#BF4F74"};
//   background: papayawhip;
//   border: none;
//   border-radius: 3px;
// `;

// // Render a styled text input with the standard input color, and one with a custom input color
// render(
//   <div>
//     <Input defaultValue="@probablyup" type="text" />
//     <Input defaultValue="@geelen" type="text" $inputColor="rebeccapurple" />
//   </div>
// );
