import HubSpotContactForm from "./HubSpotContactForm";
import StyledContent from "./styledComponents/StyledContent";
import StyledDivContainer from "./styledComponents/StyledDivContainer";
import StyledTitle from "./styledComponents/StyledTitle";

const Unsubscribe = () => {
  return (
    <StyledDivContainer>
      <StyledContent>
        <StyledTitle>Unsubscribe</StyledTitle>
        <HubSpotContactForm
          title="We're sorry to see you go!"
          formId="bb5c2f15-cdf3-4f78-91a5-ee1f4a7369eb"
        />
      </StyledContent>
    </StyledDivContainer>
  );
};

export default Unsubscribe;
