import { useParams, useResolvedPath } from "react-router-dom";
import BlogEntry from "./BlogEntry";
import { getBlogSource } from "../utils/urlUtils";
import useTrack from "../utils/useTrack";
import { LISTING_PAGE } from "../constants/pageTypes";

const BlogIndividual = () => {
  const { date } = useParams();
  const pathname = useResolvedPath().pathname;
  const source = getBlogSource({ pathname });

  const entryIdx = source.findIndex(
    (item) => item.date.toString() === date.toString()
  );
  const nextBlogIdx = entryIdx + 1;
  const nextBlogDate =
    nextBlogIdx < source.length ? source[nextBlogIdx].date : undefined;
  const prevBlogIdx = entryIdx - 1;
  const prevBlogDate = prevBlogIdx >= 0 ? source[prevBlogIdx].date : undefined;

  useTrack({ path: pathname, contentType: LISTING_PAGE });

  return (
    <BlogEntry
      entry={source[entryIdx]}
      nextBlogDate={nextBlogDate}
      prevBlogDate={prevBlogDate}
    />
  );
};

export default BlogIndividual;
