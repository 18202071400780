import {
  CCarousel,
  CCarouselItem,
  CImage,
  CModal,
  CModalBody,
  CModalHeader,
} from "@coreui/react";
import { getAltText } from "../utils/urlUtils";

const BlogImageCarousel = ({
  activeIndex,
  images,
  isCarouselOpen,
  onToggleCarousel,
  setActiveIndex,
  blogTitle,
}) => {
  return (
    <CModal
      alignment="center"
      onClose={() => onToggleCarousel(false)}
      visible={isCarouselOpen}
    >
      <CModalBody>
        <CModalHeader></CModalHeader>
        <CCarousel
          controls
          indicators
          activeIndex={activeIndex}
          onSlid={setActiveIndex}
        >
          {images.map((image) => {
            const altText = getAltText({ fileName: image, title: blogTitle });
            return (
              <CCarouselItem key={image}>
                <CImage
                  className="d-block w-100"
                  src={require(`../images/${image}`)}
                  alt={altText}
                />
              </CCarouselItem>
            );
          })}
        </CCarousel>
      </CModalBody>
    </CModal>
  );
};

export default BlogImageCarousel;
