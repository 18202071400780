import { useNavigate, useResolvedPath } from "react-router-dom";
import { getAltText, getBlogSource, getBlogType } from "../utils/urlUtils";
import BlogImageTile from "./BlogImageTile";
import styled from "styled-components";
import { SCREEN_WIDTHS } from "../constants/screenSizes";
import StyledDivContainer from "./styledComponents/StyledDivContainer";
import { getFirstImageFileName, getFirstImageSrc } from "../utils/getterUtils";
import placeholder from "../images/childrens-placeholder.jpeg";
import useTrack from "../utils/useTrack";
import { LISTING_PAGE } from "../constants/pageTypes";

const StyledBlogGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 45px;
  margin-bottom: 150px;
  max-width: 1000px;

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    flex-direction: row;
    margin-top: 0px;
  }
`;

const BlogGallery = () => {
  const pathname = useResolvedPath().pathname;
  const source = getBlogSource({ pathname });
  const blogType = getBlogType({ pathname });
  const blogsToShow = [...source].reverse();
  const navigate = useNavigate();

  const handleNavigateTo = (src) => {
    navigate(src);
  };

  useTrack({ path: pathname, contentType: LISTING_PAGE });

  return (
    <StyledDivContainer>
      <StyledBlogGallery>
        {blogsToShow.map((entry) => {
          const src =
            getFirstImageSrc({ content: entry.content }) || placeholder;
          const to = `/${blogType}/${entry.date}`;
          const altText = getAltText({
            fileName: getFirstImageFileName({ content: entry.content }),
            title: entry.title,
          });
          return (
            <BlogImageTile
              key={entry.date}
              blogType={blogType}
              date={entry.date}
              title={entry.title}
              src={src}
              onClick={() => handleNavigateTo(to)}
              altText={altText}
            />
          );
        })}
      </StyledBlogGallery>
    </StyledDivContainer>
  );
};

export default BlogGallery;