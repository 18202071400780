import useLazyImage from "../utils/useLazyImg";
import {
  getRandomColorObj,
  getDarkerVariant,
  getColorFromObj,
} from "../utils/colorUtils";
import styled from "styled-components";
import { SCREEN_WIDTHS } from "../constants/screenSizes";
import { useMemo } from "react";
import { StyledLazyImage } from "./StyledLazyImage";
import { BLACK } from "../constants/colors";

const StyledGalleryImageTile = styled.div`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  flex-basis: calc(33.3333% - 60px);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  transition: background-color 0.25s ease-in-out;
  text-align: center;

  @media screen and (max-width: ${SCREEN_WIDTHS.desktop}) {
    flex-basis: calc(50% - 60px);
  }

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    margin-top: 30px;
    flex-basis: 100%;
  }

  &:hover {
    background-color: ${({ $darkerColor }) => $darkerColor};

    .blog-image {
      filter: brightness(80%);
    }
  }
`;

const StyledBlogEntryImageTile = styled.div`
  cursor: pointer;
  display: flex;
  flex-basis: calc(33.3333% - 60px);
  flex-direction: column;
  flex-grow: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;

  @media screen and (max-width: ${SCREEN_WIDTHS.desktop}) {
    flex-basis: calc(50% - 60px);
  }

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    flex-basis: 100%;
  }
`;

const StyledBlogTitle = styled.div`
  align-items: center;
  color: ${BLACK};
  cursor: pointer;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: ${({ fontSize }) => {
    return `${fontSize ? fontSize + ";" : "calc(1.3rem + 0.6vw);"}`;
  }}
  letter-spacing: 2px;
  min-height: 100px;
  padding: 30px;
  text-decoration: none;
  text-underline: none;

  &:hover {
    color: ${BLACK};
  }
`;

const StyledBlogImage = styled(StyledLazyImage)`
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition || "center center"};
  flex-grow: 1;
  margin-top: 30px 0px;
  max-width: unset;
  min-height: 400px;
  transition: filter 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: filter 0.25s ease-in-out, opacity 0.25s ease-in-out;

  @media screen and (max-width: ${SCREEN_WIDTHS.desktop}) {
    flex-basis: calc(50% - 24px);
    min-height: 350px;
  }

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    flex-basis: 100%;
    min-height: 500px;
  }
`;

const BlogImageTile = ({
  blogType,
  date,
  title,
  src,
  onClick,
  id,
  altText,
}) => {
  const lazyImgSrc = useLazyImage(src);

  // together, the useMemo wrappers prevent changing color on re-render
  const randomColorObj = useMemo(() => {
    return getRandomColorObj();
  }, []);

  const randomColor = useMemo(() => {
    return getColorFromObj(randomColorObj);
  }, [randomColorObj]);
  const darkerColor = useMemo(() => {
    return getDarkerVariant(randomColorObj);
  }, [randomColorObj]);

  if (!title) {
    return (
      <StyledBlogEntryImageTile
        key={date}
        onClick={onClick} // toggle carousel
      >
        <StyledBlogImage
          $backgroundPosition={
            blogType === "handmades" ? "center top" : "center center"
          }
          alt={altText}
          className="blog-image"
          id={id}
          url={lazyImgSrc}
        />
      </StyledBlogEntryImageTile>
    );
  }

  return (
    <StyledGalleryImageTile
      $backgroundColor={randomColor}
      $darkerColor={darkerColor}
      className="in-gallery"
      key={date}
      onClick={onClick} // navigate to blog entry
    >
      <StyledBlogTitle fontSize="0.9rem">{title}</StyledBlogTitle>
      <StyledBlogImage
        $backgroundPosition={
          blogType === "handmades" ? "center top" : "center center"
        }
        alt={altText}
        className="blog-image"
        id={id}
        url={lazyImgSrc}
      />
    </StyledGalleryImageTile>
  );
};

export default BlogImageTile;
