import { useResolvedPath } from "react-router-dom";
import { getAltText, getBlogDate, getBlogType } from "../utils/urlUtils";
import BlogImageTile from "./BlogImageTile";
import BlogImageCarousel from "./BlogImageCarousel";
import { useState } from "react";

const BlogContentImages = ({
  images,
  isCarouselOpen,
  onToggleCarousel,
  blogTitle,
}) => {
  const pathname = useResolvedPath().pathname;
  const blogType = getBlogType({ pathname });
  const date = getBlogDate({ pathname });

  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggleCarousel = (evt) => {
    evt.preventDefault();
    const index = images.indexOf(evt.target.id);
    setActiveIndex(index);
    onToggleCarousel(true);
  };

  return (
    <div className="row justify-content-md-center">
      <BlogImageCarousel
        activeIndex={activeIndex}
        images={images}
        isCarouselOpen={isCarouselOpen}
        onToggleCarousel={onToggleCarousel}
        setActiveIndex={setActiveIndex}
        blogTitle={blogTitle}
      />
      {images.map((image) => {
        const altText = getAltText({ fileName: image, title: blogTitle });
        return (
          <BlogImageTile
            key={image}
            blogType={blogType}
            date={date}
            src={require(`../images/${image}`)}
            onClick={handleToggleCarousel}
            id={image}
            altText={altText}
          />
        );
      })}
    </div>
  );
};

export default BlogContentImages;
