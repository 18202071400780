import styled from "styled-components";

export const StyledH2 = styled.h2`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
`;

export const StyledH3 = styled.h3`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  margin-top: 0.5rem;
`;
