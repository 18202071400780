import blog from "../constants/blog.json";
import { HANDMADES } from "../constants/blogTypes";
import handmades from "../constants/handmades.json";
import { SUBSCRIBE } from "../constants/routes";

export const getBlogType = ({ pathname }) => {
  const regex = /([^/]+)/;
  const match = pathname.match(regex);
  return match && match[1];
};

export const getBlogDate = ({ pathname }) => {
  const regex = /\/(blog|handmades)\/(\d+)/;
  const match = pathname.match(regex);
  return match && match[2];
};

export const getBlogSource = ({ pathname }) => {
  const source = getBlogType({ pathname }) === HANDMADES ? handmades : blog;
  return source;
};

export const isLocalhost = () => {
  return !!window.location.href.match("localhost");
};

export const isSubscribe = () => {
  return !!window.location.href.match(SUBSCRIBE);
};

export const getAltText = ({ fileName, title }) => {
  if (!fileName) return "boston-childrens-hospital";
  return `${title.split(" ").join("-").toLowerCase().replace("---", "-")}-${
    fileName.split("-")[1].split(".jpg")[0]
  }`;
};
