import StyledLink from "./styledComponents/StyledLink";
import blogs from "../constants/blog.json";
import SocialLinks from "./SocialLinks";
import styled from "styled-components";

const StyledMainMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const MainMenu = () => {
  const mostRecentBlogDate = blogs[blogs.length - 1].date;
  const mostRecentBlogRoute = "/blog/" + mostRecentBlogDate;

  return (
    <StyledMainMenu>
      <StyledLink to={mostRecentBlogRoute}>Jessie's Blog</StyledLink>
      <SocialLinks />
    </StyledMainMenu>
  );
};

export default MainMenu;
