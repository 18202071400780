import styled from "styled-components";
import BlogContentImages from "./BlogContentImages";
import { StyledH2, StyledH3 } from "./styledComponents/StyledHeadings";
import BlogVideoModal from "./BlogVideoModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { PURPLE, TEAL } from "../constants/colors";

const StyledParagraph = styled.p`
  margin: 18px 0;
`;

const StyledDisclaimer = styled.p`
  margin: 18px 40px;
  font-style: italic;
  font-size: 14px;
`;

const QuoteParagraph = styled.p`
  margin: 0px 50px;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  margin-top: ${({ marginTop }) => marginTop || 0};
`;

const QuoteContainer = styled.div`
  margin: 20px 50px;
`;

const StyledLink = styled(Link)`
  color: ${PURPLE};
  text-decoration: none;
  text-underline: none;
  border: none;
  height: 8rem;

  &:hover {
    color: ${TEAL};
  }
`;

const BlogContent = ({ item, blogTitle }) => {
  // if (item.isCensored) return null;
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);

  if (item.type === "list") {
    return (
      <ul>
        {item.value.map((li, idx) => {
          return <li key={idx}>{li}</li>;
        })}
      </ul>
    );
  }
  if (item.type === "images") {
    return (
      <BlogContentImages
        images={item.value}
        isCarouselOpen={isCarouselOpen}
        onToggleCarousel={setIsCarouselOpen}
        blogTitle={blogTitle}
      />
    );
  }

  if (item.type === "video") {
    return <BlogVideoModal src={item.value} blogTitle={blogTitle} />;
  }

  if (item.type === "h2") {
    return <StyledH2>{item.value}</StyledH2>;
  }

  if (item.type === "h3") {
    return <StyledH3>{item.value}</StyledH3>;
  }

  if (item.type === "quote") {
    return (
      <QuoteContainer>
        {item.value.map((li, idx) => {
          return (
            <QuoteParagraph
              key={idx}
              fontWeight={item.fontWeight}
              marginTop={item.marginTop}
            >
              {li}
            </QuoteParagraph>
          );
        })}
      </QuoteContainer>
    );
  }

  if (item.type === "link") {
    return (
      <div>
        <StyledLink to={item.href}>{item.value}</StyledLink>
      </div>
    );
  }

  if (item.type === "disclaimer") {
    return <StyledDisclaimer>{item.value}</StyledDisclaimer>;
  }

  return <StyledParagraph>{item.value}</StyledParagraph>;
};

export default BlogContent;
