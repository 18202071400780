"use es6";
import { useEffect, useState } from "react";

const useLazyImage = (src) => {
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImageSource(src);
  }, [src]);

  return imageSource;
};

export default useLazyImage;
