import HubSpotContactForm from "./HubSpotContactForm";
import StyledContent from "./styledComponents/StyledContent";
import StyledDivContainer from "./styledComponents/StyledDivContainer";
import StyledTitle from "./styledComponents/StyledTitle";
import { STANDARD_PAGE } from "../constants/pageTypes";
import useTrack from "../utils/useTrack";
import { SUBSCRIBE } from "../constants/routes";
import ContactLinks from "./ContactLinks";
import styled from "styled-components";

const StyledContactLinks = styled.div`
  margin-top: 33px;
`;

const Subscribe = () => {
  useTrack({ path: `/${SUBSCRIBE}`, contentType: STANDARD_PAGE });

  return (
    <StyledDivContainer>
      <StyledContent>
        <StyledTitle>Keep in touch</StyledTitle>
        <HubSpotContactForm
          formId="11fa64e4-f7d9-401b-8965-1adcbd399a83"
          title="Never miss a blog update. Sign up for updates"
        />
      </StyledContent>
      <StyledContactLinks>
        <ContactLinks />
      </StyledContactLinks>
    </StyledDivContainer>
  );
};

export default Subscribe;
