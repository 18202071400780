import { CNav, CNavItem } from "@coreui/react";
import TopNavLink from "./TopNavLink";
import styled from "styled-components";
import { SCREEN_WIDTHS } from "../constants/screenSizes";
import { BLACK } from "../constants/colors";
import { useEffect, useCallback } from "react";
import { useState } from "react";

const StyledMenu = styled.div`
  .nav-tabs {
    border: none;

    @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
  }

  .nav-link {
    color: ${BLACK};
    border: 2px solid ${BLACK};
    border-radius: 0;
    margin-right: 12px;

    &:hover {
      color: rgb(196, 201, 208);
    }

    &.active {
      background: ${BLACK};
      color: white;
      border: 2px solid ${BLACK};
    }
  }
`;

const Menu = () => {
  const getButtonText = () => {
    const width = window.innerWidth;

    if (width <= 576) {
      // SCREEN_WIDTHS.mobile
      return "Jude";
    }

    return "Jude Blog";
  };

  const [judeBlogButtonText, setJudeBlogButtonText] = useState(getButtonText());

  const handleCheckWidth = useCallback(() => {
    const width = window.innerWidth;

    if (width <= 576) {
      // SCREEN_WIDTHS.mobile
      setJudeBlogButtonText("Jude");
      return;
    }

    setJudeBlogButtonText("Jude Blog");
  }, [setJudeBlogButtonText]);

  useEffect(() => {
    window.addEventListener("resize", handleCheckWidth);

    return function cleanup() {
      window.removeEventListener("resize", handleCheckWidth);
    };
  }, [handleCheckWidth]);

  return (
    <StyledMenu>
      <CNav variant="tabs">
        <CNavItem>
          <TopNavLink href="/about" title="About" />
        </CNavItem>
        <CNavItem>
          <TopNavLink href="/blog" title={judeBlogButtonText} />
        </CNavItem>
        <CNavItem>
          <TopNavLink href="/handmades" title="Handmades" />
        </CNavItem>
      </CNav>
    </StyledMenu>
  );
};

export default Menu;
