import { Link } from "react-router-dom";
import styled from "styled-components";
import { SCREEN_WIDTHS } from "../../constants/screenSizes";
import { BLACK } from "../../constants/colors";

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 1000;
  font-size: ${({ fontSize }) => {
    return `${fontSize ? fontSize + ";" : "calc(1.3rem + 0.6vw);"}`;
  }}
  letter-spacing: 2px;
  padding-left: 60px;
  color: ${BLACK};
  text-decoration: none;
  text-underline: none;
  border: none;
  height: 8rem;

  &:hover {
    color: ${BLACK};
  }

  @media screen and (max-width: ${SCREEN_WIDTHS.mobile}) {
    height: 7rem;
    padding-left: 30px;
  }
`;

export default StyledLink;
