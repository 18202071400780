import { CNavLink } from "@coreui/react";
import { useMatch } from "react-router-dom";

const TopNavLink = ({ href, title }) => {
  const match = useMatch(href);
  const isActive = match;

  return isActive ? (
    <CNavLink href={href} active={true}>
      {title}
    </CNavLink>
  ) : (
    <CNavLink href={href}>{title}</CNavLink>
  );
};

export default TopNavLink;
