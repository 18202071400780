export const getFirstImageFileName = ({ content }) => {
  const imageContent = content.find(
    (contentPiece) => contentPiece.type === "images"
  );

  if (!imageContent) return null;

  return imageContent.value[0];
};

export const getFirstImageSrc = ({ content }) => {
  const imageContent = getFirstImageFileName({ content });

  if (!imageContent) return null;

  return require(`../images/${imageContent}`);
};
