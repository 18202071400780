import styled from "styled-components";
import { TEAL } from "../constants/colors";
import instagram from "../assets/instagram.png";
import email from "../assets/email.png";
import { Link } from "react-router-dom";
import { StyledH3 } from "./styledComponents/StyledHeadings";
import React from "react";

const StyledContactLink = styled(Link)`
  color: ${TEAL};
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;

  &:hover {
    color: black;
  }
`;

const StyledIcon = styled.img`
  height: 24px;
  margin-right: 12px;
  filter: hue-rotate(180deg);
`;

const ContactLinks = () => {
  return (
    <React.Fragment>
      <StyledH3>
        <StyledIcon src={email} alt="email" />
        <StyledContactLink to="mailto:jessie.samuelson@gmail.com">
          jessie.samuelson@gmail.com
        </StyledContactLink>
      </StyledH3>
      <StyledH3>
        <StyledIcon src={instagram} alt="instagram" />
        <StyledContactLink to="https://www.instagram.com/jessie_by_hand/">
          @jessie_by_hand
        </StyledContactLink>
      </StyledH3>
    </React.Fragment>
  );
};

export default ContactLinks;
