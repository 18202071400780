// get random pastel color
export function getRandomColor() {
  return getColorFromObj(getRandomColorObj);
}

// get darker variant of color
export function getDarkerVariant(colorObj) {
  colorObj.lightness = colorObj.lightness - 10;
  return getColorFromObj(colorObj);
}

export function getRandomColorObj() {
  const hue = 360 * Math.random();
  const saturation = 25 + 70 * Math.random();
  const lightness = 85 + 10 * Math.random();

  return { hue, saturation, lightness };
}

export function getColorFromObj(colorObj) {
  const { hue, saturation, lightness } = colorObj;
  return "hsl(" + hue + "," + saturation + "%," + lightness + "%)";
}
