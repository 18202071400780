import { Link, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import { getBlogType } from "../utils/urlUtils";
import { BLACK } from "../constants/colors";

const StyledLink = styled(Link)`
  border: 2px solid ${BLACK};
  color: ${BLACK};
  padding: 9px 30px;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: ${BLACK};
  }
`;

const BlogNavButton = ({ blogDate, buttonText, to }) => {
  const pathname = useResolvedPath().pathname;
  const toValue = to || `/${getBlogType({ pathname })}/${blogDate}`;

  return <StyledLink to={toValue}>{buttonText}</StyledLink>;
};

export default BlogNavButton;
