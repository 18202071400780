import styled from "styled-components";

const StyledDivContainer = styled.div`
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default StyledDivContainer;
