import { useEffect } from "react";
import { StyledH3 } from "./styledComponents/StyledHeadings";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const HubSpotContactForm = ({ formId, title }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5651882",
          formId,
          target: "#hubspotForm",
        });
      }
    });
  }, [formId]);

  return (
    <StyledDiv>
      <StyledH3>{title}</StyledH3>
      <div id="hubspotForm"></div>
    </StyledDiv>
  );
};

export default HubSpotContactForm;
